const domain = 'https://core.robin-leads.ru'
// const domain = 'http://127.0.0.1:3000'

export default class Api {
    constructor(token) {
        this.access_token = token || localStorage.getItem('access_token');
    }

    async login(username, password) {
        try {
            const data = await this.post('login', {
                username: username,
                password: password
            })

            if (data['access_token']) {
                this.access_token = data['access_token']
                localStorage.setItem('access_token', this.access_token);

                return this.access_token
            }

            return false
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    async post(url, body) {
        try {
            return await (await fetch(`${domain}/${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.access_token
                },
                method: "POST",
                body: JSON.stringify(body)
            })).json()
        } catch (e) {
            throw e
        }
    }

    async put(url, body) {
        try {
            return await (await fetch(`${domain}/${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.access_token
                },
                method: "PUT",
                body: JSON.stringify(body)
            })).json()
        } catch (e) {
            throw e
        }
    }

    async delete(url) {
        try {
            return await (await fetch(`${domain}/${url}`, {
                headers: {
                    'Authorization': 'Bearer ' + this.access_token
                },
                method: "DELETE",
            })).json()
        } catch (e) {
            throw e
        }
    }

    async get(url) {
        try {
            return await (await fetch(`${domain}/${url}`, {
                headers: {'Authorization': 'Bearer ' + this.access_token},
                method: "GET"
            })).json()
        } catch (e) {
            throw e
        }
    }
}
