import {useState} from 'react';
// @mui
import {alpha} from '@mui/material/styles';
import {Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover} from '@mui/material';
// mocks_
import {useAuth} from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Профиль',
        icon: 'eva:person-fill',
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const {logout, user} = useAuth();

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        logout()
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={user.image || "/assets/images/avatars/avatar.png"} alt="photoURL"/>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user.username}
                    </Typography>
                    {user.email ? <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {user.email}
                    </Typography> : undefined}
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                {/*<Stack sx={{p: 1}}>*/}
                {/*    {MENU_OPTIONS.map((option) => (*/}
                {/*        <MenuItem key={option.label} onClick={handleClose}>*/}
                {/*            {option.label}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</Stack>*/}

                {/*<Divider sx={{borderStyle: 'dashed'}}/>*/}

                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    Выйти
                </MenuItem>
            </Popover>
        </>
    );
}
