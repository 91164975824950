import {Helmet} from 'react-helmet-async';
import {useTheme} from '@mui/material/styles';
import {
    Grid,
    Container,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Typography, Stack, CircularProgress
} from '@mui/material';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from 'react';
import Api from "../Api";
import {useParams} from "react-router-dom";
import {AppConversionRates, AppCurrentSubject, AppCurrentVisits, AppWebsiteVisits} from "../sections/@dashboard/app";

export default function HistoryPage() {
    const theme = useTheme();
    const {access_token, user} = useAuth();
    const [data, setData] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const {id, client_id} = useParams();
    const [isLoad, setLoad] = useState(true);

    const getData = () => {
        setLoad(true)
        const api = new Api(access_token)

        api.get('stats/history/' + id + "/" + client_id).then((res) => {
            setData(res.data || [])
            setCurrentUser(res.user || {})
            setLoad(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Helmet>
                <title> История клиента | Robin Leads </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h4" gutterBottom>
                            История клиента {client_id}
                        </Typography>
                    </Grid>
                </Grid>


                <Grid mb={3}>
                    {isLoad ? <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                        <CircularProgress/>
                    </Stack> : <>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Дата действия</TableCell>
                                        <TableCell>Продукт</TableCell>
                                        <TableCell>Действие</TableCell>
                                        <TableCell align="right">User IP</TableCell>
                                        <TableCell align="right">User Agent</TableCell>
                                        <TableCell align="right">Статус</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">{item.date}</TableCell>
                                            {item?.offer ? <TableCell style={{display: "flex", alignItems: "center", gap: 12}}>
                                                <img src={item.offer?.image} alt="" style={{
                                                    width: 44,
                                                    minWidth: 44
                                                }}/>
                                                {item.offer?.name || 'Неизвестно'}
                                            </TableCell> : <TableCell>Неизвестно</TableCell>}
                                            <TableCell>{item.action === 'conversion' ? "Конверсия" : item.action === 'click'? "Клик" : item.action}</TableCell>
                                            <TableCell align="right">{item.extra.client_ip}</TableCell>
                                            <TableCell align="right">{item.extra.user_agent}</TableCell>
                                            <TableCell style={{minWidth: 140}} align="right">{item.extra.status === 'rejected' ? <span
                                                style={{color: "#E23039"}}>Отклонено</span> : item.extra.status === 'pending' ?
                                                <span
                                                    style={{color: "#F1A105"}}>На проверке</span> : item.extra.status === 'approved' ?
                                                    <span style={{color: "#488C26"}}>Одобрено</span> :
                                                    <span>Неизвестно</span>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                </Grid>
            </Container>
        </>
    );
}
