import {Helmet} from 'react-helmet-async';
import {filter} from 'lodash';
import {useEffect, useState} from 'react';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
} from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import {UserListHead, UserListToolbar} from '../sections/@dashboard/user';
import {useAuth} from "../hooks/useAuth";
import Api from "../Api";
import {useNavigate} from "react-router-dom";

const TABLE_HEAD = [
    {id: 'index', label: '№', alignRight: false},
    {id: 'username', label: 'Имя', alignRight: false},
    {id: 'balance', label: 'Баланс', alignRight: false},
    {id: 'hold_balance', label: 'Баланс в холде', alignRight: false},
    {id: 'bonus_balance', label: 'Бонусы', alignRight: false},
    {id: 'role', label: 'Роль', alignRight: false, only_admin: true},
    {id: 'created_user', label: 'Закреплен за', alignRight: false, only_admin: true},
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UsersPage() {
    const {access_token, user} = useAuth();

    const navigate = useNavigate();

    const [open, setOpen] = useState(null);
    const [targetUser, setTargetUser] = useState(null);
    const [openModal, setOpenModal] = useState(null);
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        const api = new Api(access_token)

        api.get('users').then((users) => {
            setUsersList(users.map((u, index) => {
                return {...u, index: index + 1}
            }))
        })
    }, [])

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleOpenMenu = (event) => {
        const _user = usersList.find((user) => {
            if (user.id === parseInt(event.currentTarget.value)) {
                return user
            }
        })

        if (_user) {
            setTargetUser(_user)
        }
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usersList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleModalClose = (event) => {
        setOpenModal(false)
    }

    const handleDeleteUser = (event) => {
        setUsersList(usersList.map((user) => {
            return {
                ...user,
                is_delete: user.id === openModal.id || user.is_delete
            }
        }))

        const api = new Api(access_token)
        api.delete(`users/${openModal.id}`)

        setOpenModal(null)
        setOpen(null)
    }

    const handleOpenModalDeleteUser = (event) => {
        const id = parseInt(open.value)

        if (id) {
            const item = usersList.find((item) => item.id === id)
            setOpenModal(item)
        }
    }

    const handleEditUser = (event) => {
        const id = parseInt(open.value)

        if (id) {
            navigate("/dashboard/users/update/" + id)
        }
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

    const filteredUsers = applySortFilter(usersList, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Пользователи | Robin Leads </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Пользователи
                    </Typography>
                    {user.role === 'super_admin' ? <Button onClick={() => {
                        navigate("/dashboard/users/create")
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
                        Создать
                    </Button> : undefined}
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName}
                                     onFilterName={handleFilterByName}/>

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={usersList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const {
                                            id,
                                            index,
                                            username,
                                            fullname,
                                            image,
                                            role,
                                            balance,
                                            bonus_balance,
                                            hold_balance,
                                            created_user,
                                            is_delete,
                                            password
                                        } = row;
                                        const selectedUser = selected.indexOf(username) !== -1;

                                        return (
                                            <TableRow hover key={index} tabIndex={-1} selected={selectedUser} style={{
                                                display: is_delete ? "none" : "table-row",
                                                background: role === 'admin' ? "#f1f1ff" : role === 'super_admin' ? "#fff9e0" : '#ffffff'
                                            }}>

                                                <TableCell align="left" padding="normal">{index}</TableCell>

                                                <TableCell scope="row" padding="normal">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar src={image || "/assets/images/avatars/avatar.png"}
                                                                alt="photoURL" style={{width: 60, height: 60}}/>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {fullname ?
                                                                <i><b>{fullname}</b></i> : undefined} {fullname ?
                                                            <br/> : undefined}
                                                            {username}<br/>{password}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">{balance || 0}р</TableCell>
                                                <TableCell align="left">{hold_balance || 0}р</TableCell>
                                                <TableCell align="left">{bonus_balance || 0}</TableCell>

                                                {user.role === 'super_admin' ?
                                                    <TableCell
                                                        align="left">{role === "super_admin" ? "Администратор" : (role === "admin") ? "Продвинутый" : "Пользователь"}</TableCell> : undefined}

                                                {user.role === 'super_admin' ?
                                                    <TableCell
                                                        align="left">{created_user?.username || "Никто"}</TableCell> : undefined}

                                                <TableCell align="right">
                                                    <IconButton size="large" color="inherit"
                                                                onClick={handleOpenMenu}
                                                                value={id}>
                                                        <Iconify icon={'eva:more-vertical-fill'}/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Ничего нет
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        Ничего не найдено по запросу &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br/> Попробуйте проверить на опечатки или использовать полные
                                                        слова.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Показывать по:"
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={usersList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 190,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {user.role === 'super_admin' ? <>
                    <MenuItem onClick={handleEditUser}>
                        <Iconify icon={'eva:edit-fill'} sx={{mr: 2}}/>
                        Редактировать
                    </MenuItem>
                    {targetUser?.role !== 'super_admin' ?
                        <>
                            <hr/>
                            <MenuItem sx={{color: 'error.main'}} onClick={handleOpenModalDeleteUser}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{mr: 2}}/>
                                Удалить
                            </MenuItem>
                        </> : undefined}

                    <hr/>
                </> : undefined}

                <MenuItem onClick={() => {
                    navigate("/dashboard/reports/statistics/" + targetUser?.id)
                }}>
                    <Iconify icon={'tabler:device-analytics'} sx={{mr: 2}}/>
                    По времени
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate("/dashboard/reports/offers/" + targetUser?.id)
                }}>
                    <Iconify icon={'tabler:device-analytics'} sx={{mr: 2}}/>
                    По офферам
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate("/dashboard/reports/conversions/" + targetUser?.id)
                }}>
                    <Iconify icon={'tabler:device-analytics'} sx={{mr: 2}}/>
                    По конверсии
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate("/dashboard/reports/finance/" + targetUser?.id)
                }}>
                    <Iconify icon={'tabler:device-analytics'} sx={{mr: 2}}/>
                    Счета
                </MenuItem>
            </Popover>

            <Dialog
                open={!!openModal}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены что хотите удалить этого пользователя?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Восстановить пользователя после удаления будет невозможно.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteUser}>Удалить</Button>
                    <Button onClick={handleModalClose} autoFocus variant="outlined" color="error">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
