import {useRef, useState} from 'react';
// @mui
import {Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import Api from "../../../Api";
import {useAuth} from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const {login} = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [isErrorLogin, setIsErrorLogin] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const loginRef = useRef(null)
    const passwordRef = useRef(null)

    const handleClick = async () => {
        try {
            const loginVal = loginRef.current.value
            const passwordVal = passwordRef.current.value

            if (!loginVal) {
                return setIsErrorLogin(true)
            }

            if (!passwordVal) {
                return setIsErrorPassword(true)
            }

            const api = new Api()

            setIsLoad(true)

            const data = await api.login(loginVal, passwordVal)

            if (!data) {
                setIsLoad(false)
                return setIsError(true)
            }

            setIsErrorLogin(false)
            setIsErrorPassword(false)
            setIsError(false)
            setIsLoad(false)

            login(data)
        } catch (e) {
            setIsError(true)
            setIsLoad(false)
        }
    };

    return (
        <>
            <Stack spacing={3} sx={{my: 2}}>
                <TextField name="login" label="Login" inputRef={loginRef} required={true} error={isErrorLogin} variant="filled"/>

                <TextField
                    variant="filled"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    inputRef={passwordRef}
                    required={true}
                    error={isErrorPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            {isError ? <Stack spacing={3} sx={{my: 2}}>
                <Alert severity="error">Ошибка авторизации. <br/><i>Проверьте введенный логин и пароль.</i></Alert>
            </Stack> : undefined}

            <LoadingButton fullWidth size="large" type="submit" loading={isLoad} variant="contained" onClick={handleClick}>
                Войти
            </LoadingButton>
        </>
    );
}
