// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfigReports = (id) => {
  return [
    {
      title: 'По времени',
      path: '/dashboard/reports/statistics/' + id,
      icon: icon('ic_analytics'),
    },
    {
      title: 'По офферам',
      path: '/dashboard/reports/offers/' + id,
      icon: icon('ic_analytics'),
    },
    {
      title: 'По конверсии',
      path: '/dashboard/reports/conversions/' + id,
      icon: icon('ic_analytics'),
    },
    {
      title: 'Счета',
      path: '/dashboard/reports/finance/' + id,
      icon: icon('ic_analytics'),
    },
  ];
}

export default navConfigReports;
