import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Link, Card, Grid, CardContent, Button, Stack} from '@mui/material';
import SvgColor from '../../../components/svg-color';
import {useAuth} from "../../../hooks/useAuth";
import Iconify from "../../../components/iconify";

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
});

const StyledInfo = styled('div')(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

StoreCard.propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func
};

export default function StoreCard({item, onDelete, onEdit}) {
    const {access_token, user} = useAuth();
    const {title, price, image, link, is_delete} = item;

    if (is_delete) return undefined

    return <Grid item xs={12} sm={6} md={3}>
        <Card sx={{position: 'relative'}}>
            <StyledCardMedia
                sx={{
                    pt: 'calc(100% * 0.4)'
                }}
            >
                <SvgColor
                    color="paper"
                    src="/assets/icons/shape-avatar.svg"
                    sx={{
                        width: 80,
                        height: 36,
                        zIndex: 9,
                        bottom: -15,
                        position: 'absolute',
                        color: 'background.paper',
                    }}
                />
                <StyledCover alt={title} src={image}/>
            </StyledCardMedia>

            <CardContent
                sx={{pt: 4,}}
            >

                <StyledTitle
                    color="inherit"
                    underline="none"
                    sx={{typography: 'h5', height: 60}}
                >
                    {price ? <><small>{price ? price + " бонусов" : undefined}</small><br/></> : undefined}
                    {title}
                </StyledTitle>

                <StyledInfo>
                    <Button fullWidth size="large" type="submit" variant="contained" href={link}
                            disabled={user.bonus_balance < price || !!!price || !!!link}> Купить </Button>
                    {user.role === 'super_admin' ? <Stack direction="row" alignItems="center" justifyContent="center" mt={2} gap={2}>
                        <Button size="small" color="error" onClick={() => onDelete(item)}> Удалить </Button>
                        <Button size="small" onClick={() => onEdit(item)}> Редактировать </Button>
                    </Stack> : undefined}
                </StyledInfo>
            </CardContent>
        </Card>
    </Grid>
}
