import {Helmet} from 'react-helmet-async';
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    FormControl,
    Select,
    Grid,
    InputAdornment,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
} from '@mui/material';
import Iconify from '../components/iconify';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useRef, useState} from "react";
import Api from "../Api";
import {useNavigate, useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function UpdateStorePage() {
    const {access_token, user} = useAuth();
    const navigate = useNavigate();
    const {id} = useParams();

    const [data, setData] = useState(null)
    const [openModal, setOpenModal] = useState(null);

    const titleRef = useRef(null);
    const priceRef = useRef(null);
    const imageRef = useRef(null);
    const linkRef = useRef(null);

    useEffect(() => {
        const api = new Api(access_token)

        api.get(`store/${id}`).then((res) => {
            setData(res)
        })
    }, [id])

    const handleUpdateStore = () => {
        const title = titleRef.current.value,
            image = imageRef.current.value,
            price = priceRef.current.value,
            link = linkRef.current.value

        const api = new Api(access_token)

        api.put('store/' + id, {
            title: title,
            price: price,
            image: image,
            link: link,
        }).then((response) => {
            setOpenModal(true)
        })
    }

    return (
        <>
            <Helmet>
                <title>Обновить товар | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Обновить товар
                    </Typography>
                    <Button onClick={handleUpdateStore} variant="contained"
                            startIcon={<Iconify icon="dashicons:update"/>}>
                        Обновить
                    </Button>
                </Stack>

                <Card>
                    {data ? <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>

                            <TextField name="title" label="Название" required variant="outlined" fullWidth
                                       inputRef={titleRef} defaultValue={data?.title}/>

                            <TextField name="price" label="Стоимость" required variant="outlined" fullWidth
                                       inputRef={priceRef} type="number" defaultValue={data?.price}/>

                            <TextField name="link" label="Ссылка" variant="outlined" fullWidth type="url"
                                       inputRef={linkRef} defaultValue={data?.link} required/>

                            <TextField name="link" label="Изображение" variant="outlined" fullWidth defaultValue={data?.image} type="url"
                                       inputRef={imageRef}/>

                        </Stack>
                    </FormControl> : undefined }
                </Card>

                <Dialog
                    open={!!openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        Товар {titleRef?.current?.value} успешно обновлен.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenModal(false)
                        }} autoFocus variant="contained" color="error">
                            Закрыть
                        </Button>
                        <Button onClick={() => {
                            setOpenModal(false)
                            navigate("/dashboard/store")
                        }} autoFocus variant="contained" color="primary">
                            В магазин
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}
