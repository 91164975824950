import {
    Stack,
    Button,
    MenuItem,
    DialogTitle,
    DialogActions,
    Dialog,
    DialogContent,
    FormControl,
    Select, TextField, Autocomplete,
    Checkbox, FormControlLabel
} from '@mui/material';
import {useState, useEffect, useRef} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useAuth} from "../../hooks/useAuth";
import Api from "../../Api";
import {LoadingButton} from "@mui/lab";

export default function ProductLinkModal(props) {
    const theme = useTheme();
    const {access_token, user} = useAuth();
    const [isLoad, setIsLoad] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [usersList, setUsersList] = useState([]);
    const [currentUser, setCurrentUser] = useState(null)
    const [linkData, setLinkData] = useState(null)

    const priceForUserRef = useRef(null);
    const linkRef = useRef(null);
    const isCustomLinkRef = useRef(null);
    const sumRef = useRef(null);

    useEffect(() => {
        const api = new Api(access_token)
        setIsLoad(true)
        api.get('users').then((users) => {
            setUsersList(users)
            setIsLoad(false)
        })
    }, [])

    function handleChangeAutocomplete(event) {
        const _user = usersList.find(_ => (_.username === event.currentTarget.textContent))

        const api = new Api(access_token)

        api.get('links/' + _user.id + "/" + props.product.id).then((data) => {
            setLinkData(data)
        }).catch(() => setLinkData({}))

        setCurrentUser(_user)
    }

    function handleConfirm(event) {
        const api = new Api(access_token)

        api.post('links', {
            product: props?.product?.id,
            user: currentUser.id,
            price: priceForUserRef?.current?.value,
            link: linkRef?.current?.value,
            is_custom_link: isCustomLinkRef?.current?.checked,
            sum: sumRef?.current?.value
        }).then((data) => {
            props.onClose()
        })
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
            <DialogTitle>
                Привязать к пользователю продукт - "{props.product.name}"
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <Stack spacing={2} paddingTop={2}>
                        <Autocomplete
                            onChange={handleChangeAutocomplete}
                            renderInput={(params) => <TextField {...params} label="Пользователь"/>}
                            options={usersList.filter((_user) => (_user.role !== 'super_admin')).map(_user => (_user.username))}/>

                        {
                            user.role === 'super_admin' ? <>
                                <div>
                                    <small>Текущее значение: {linkData?.link || "Нету"}</small>
                                    <TextField margin={'dense'} label="Ссылка" variant="outlined" fullWidth
                                               inputRef={linkRef} type="url"/>
                                </div>
                                <div>
                                    <small>Текущее значение: {linkData?.is_custom_link !== undefined ? linkData?.is_custom_link.toString() : "Нету"}</small><br/>
                                    <FormControlLabel control={<Checkbox inputRef={isCustomLinkRef} />} label="Ограничить по IP" />
                                </div>
                            </> : undefined
                        }

                        <div>
                            <small>Текущее значение: {linkData?.price || "0"}</small>
                            <TextField margin={'dense'} label="Цена для пользователя" variant="outlined" fullWidth
                                       inputRef={priceForUserRef} type="number"/>
                        </div>
                        <div>
                            <small>Текущее значение: {linkData?.sum || "0"}</small>
                            <TextField margin={'dense'} label="Отбавляемая сумма" variant="outlined" fullWidth
                                       inputRef={sumRef} type="number"/>
                        </div>

                    </Stack>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleConfirm} loading={isLoad}>Применить</LoadingButton>
                <Button onClick={props.onClose} autoFocus variant="outlined" color="error">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}
