import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Button, Typography, Container, Box} from '@mui/material';
import {useEffect, useState} from 'react';
import Api from "../Api";
import {useAuth} from "../hooks/useAuth";
import {useParams} from "react-router-dom";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RedirectPage() {
    const [link, setLink] = useState(undefined);
    const {path} = useParams()

    useEffect(() => {
        const api = new Api()

        api.get(`links/custom/${path}`).then((data) => {
            if(data?.link !== null) {
                setLink(data?.link)
            } else {
                setLink(null)
                window.location.replace('https://ya.ru/');
            }
        }).catch(() => {
            setLink(null)
            window.location.replace('https://ya.ru/');
        })
    }, [])

    useEffect(() => {
        if (link) {
            const api = new Api()

            api.post(`links/custom/${path}`).then((data) => {
                if (data?.status) {
                    window.location.replace(link);
                } else {
                    setLink(null)
                    window.location.replace('https://ya.ru/');
                }
            }).catch(() => {
                setLink(null)
                window.location.replace('https://ya.ru/');
            })
        }
    }, [link])

    return (
        <>
            <Helmet>
                <title>Идет перенаправление...</title>
            </Helmet>

            <Container>
                <StyledContent sx={{textAlign: 'center', alignItems: 'center'}}>
                    <Typography variant="h4">Идет перенаправление...</Typography>

                    <Typography sx={{color: 'text.secondary', my: {xs: 2, sm: 2}}}>
                        Пожалуйста подождите
                    </Typography>
                </StyledContent>
            </Container>
        </>
    );
}
