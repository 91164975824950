import {
    Stack,
    Button,
    DialogTitle,
    DialogActions,
    Dialog,
    DialogContent,
    FormControl,
    TextField,
} from '@mui/material';
import {useRef, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useAuth} from "../../hooks/useAuth";
import Api from "../../Api";
import {LoadingButton} from "@mui/lab";

export default function ProductAnInvoiceUser(props) {
    const theme = useTheme();
    const {access_token, user} = useAuth();
    const [isLoad, setIsLoad] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const priceRef = useRef(null);

    function handleConfirm(event) {
        const api = new Api(access_token)

        setIsLoad(true)
        api.put(`products/${props.product.id}/update/price${props?.type === 'admin' ? '/admin' : ''}`, {
            price: priceRef.current.value
        }).then((data) => {
            setIsLoad(false)
            props.onClose()
        })
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
            <DialogTitle>
                Изменить цену для ВСЕХ {props?.type === 'admin' ? "админов" : "пользователей"} - "{props.product.name}"
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <Stack spacing={2} paddingTop={2}>
                        <div>
                            <TextField margin={'dense'} label="Цена" variant="outlined" fullWidth
                                       inputRef={priceRef} type="number"/>
                        </div>
                    </Stack>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleConfirm} loading={isLoad}>Применить</LoadingButton>
                <Button onClick={props.onClose} autoFocus variant="outlined" color="error">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}
