import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Router from './routes';
import ThemeProvider from './theme';
import {StyledChart} from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import {AuthProvider} from "./hooks/useAuth";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function App() {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <ThemeProvider>
                    <ScrollToTop/>
                    <StyledChart/>
                    <AuthProvider>
                        <Router/>
                    </AuthProvider>
                </ThemeProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
}
