import {Helmet} from 'react-helmet-async';
import {Grid, Button, Container, Stack, Typography} from '@mui/material';
import Iconify from '../components/iconify';
import StoreCard from "../sections/@dashboard/blog/StoreCard";
import {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Api from "../Api";

export default function StorePage() {
    const {access_token, user} = useAuth();
    const navigate = useNavigate();

    const [items, setItems] = useState([])

    useEffect(() => {
        const api = new Api(access_token)

        api.get('store').then((_items) => {
            setItems(_items)
        })
    }, [])

    function handleDelete(item) {
        const api = new Api(access_token)

        setItems(items.map((_item) => {
            return {
                ..._item,
                is_delete: _item.id === item.id || _item.is_delete
            }
        }))

        api.delete(`store/${item.id}`)
    }

    function handleEdit(item) {
        return navigate('/dashboard/store/update/' + item.id)
    }

    return (
        <>
            <Helmet>
                <title> Магазин | Robin Leads </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Магазин
                    </Typography>
                    {user.role === 'super_admin' ? <Button onClick={() => {
                        navigate("/dashboard/store/create")
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
                        Создать
                    </Button> : undefined}
                </Stack>

                <Grid container spacing={3}>
                    {items.map((item,) => (
                        <StoreCard key={item.id} item={item} onDelete={handleDelete} onEdit={handleEdit}/>
                    ))}
                </Grid>
            </Container>
        </>
    );
}
