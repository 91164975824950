import {Helmet} from 'react-helmet-async';
import {
    Grid,
    Container,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Typography, Button, Stack, CircularProgress
} from '@mui/material';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from 'react';
import Api from "../Api";
import {useParams} from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {DateTime} from "luxon";
import Iconify from "../components/iconify";
import {AppConversionRates} from "../sections/@dashboard/app";

export default function OffersPage() {
    const {access_token, user} = useAuth();
    const [stats, setStats] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const {id} = useParams();
    const [date, setDate] = useState([DateTime.now().minus({month: 1}).toJSDate(), DateTime.now().toJSDate()]);
    const [isLoad, setLoad] = useState(true);

    const getData = (dates) => {
        setLoad(true)
        const _date = dates || date

        const api = new Api(access_token)
        const query = `?from=${DateTime.fromJSDate(_date[0]).toFormat('yyyy-MM-dd')}&to=${DateTime.fromJSDate(_date[1]).toFormat('yyyy-MM-dd')}`

        api.get('stats/time/' + id + query).then((res) => {
            setStats(res.data.report || [])
            setCurrentUser(res.user || {})
            setLoad(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSelectDate = (dates) => {
        setDate(dates)
        getData(dates)
    }

    return (
        <>
            <Helmet>
                <title> Статистика | Robin Leads </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h4" gutterBottom>
                            Статистика по
                            времени {currentUser?.username ? 'пользователя - ' + currentUser?.username : undefined}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} textAlign={'right'}>
                        <DateRangePicker onChange={handleSelectDate} value={date} format="dd.MM.y" locale="RU-ru"
                                         maxDate={DateTime.now().toJSDate()} clearIcon={null}
                                         calendarIcon={<Iconify icon={'ph:calendar-duotone'}/>} disabled={isLoad}/>
                    </Grid>
                </Grid>

                <Grid mb={3}>
                    {isLoad ? <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                        <CircularProgress/>
                    </Stack> : <>
                        <Grid mb={3}>
                            <AppConversionRates
                                title="Уникальных кликов"
                                chartData={[
                                    ...stats.map(item => ({
                                        label: DateTime.fromFormat(item.dm_lead_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy'),
                                        value: item.mt_clicks_unique
                                    }))
                                ]}
                            />
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Клик. всего</TableCell>
                                        <TableCell>Клик. уник.</TableCell>
                                        <TableCell>Лид. всего</TableCell>
                                        <TableCell>Лид. прин.</TableCell>
                                        <TableCell>Лид. на проверке</TableCell>
                                        <TableCell>Лид. отклонено</TableCell>
                                        <TableCell>CR, %</TableCell>
                                        <TableCell>AR, %</TableCell>
                                        <TableCell>EPC</TableCell>
                                        <TableCell align={"right"}>Дата</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.map((stat, index) => (
                                        <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>

                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{stat.mt_clicks_total}</TableCell>
                                            <TableCell>{stat.mt_clicks_unique}</TableCell>
                                            <TableCell>{stat.mt_leads_total}</TableCell>
                                            <TableCell>{stat.mt_leads_approved}</TableCell>
                                            <TableCell>{stat.mt_leads_pending}</TableCell>
                                            <TableCell>{stat.mt_leads_rejected}</TableCell>
                                            <TableCell>{stat.mt_cr_u}</TableCell>
                                            <TableCell>{stat.mt_ar}</TableCell>
                                            <TableCell>{stat.mt_epc_u}</TableCell>
                                            <TableCell
                                                align={"right"}>{DateTime.fromFormat(stat.dm_lead_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                </Grid>
            </Container>
        </>
    );
}