import {Helmet} from 'react-helmet-async';
import {useTheme} from '@mui/material/styles';
import {
    Grid,
    Container,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Typography, Stack, CircularProgress
} from '@mui/material';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from 'react';
import Api from "../Api";
import {useParams} from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {DateTime} from "luxon";
import Iconify from "../components/iconify";
import {AppConversionRates, AppCurrentSubject, AppCurrentVisits, AppWebsiteVisits} from "../sections/@dashboard/app";
import {useNavigate} from "react-router-dom";

export default function ConversionsPage() {
    const theme = useTheme();
    const {access_token, user} = useAuth();
    const [conversions, setConversions] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const {id} = useParams();
    const [date, setDate] = useState([DateTime.now().minus({month: 1}).toJSDate(), DateTime.now().toJSDate()]);
    const [isLoad, setLoad] = useState(true);
    const groupByOfferId = groupBy("offer_id");
    const navigate = useNavigate();

    const getData = (dates) => {
        setLoad(true)
        const _date = dates || date

        const api = new Api(access_token)
        const query = `?from=${DateTime.fromJSDate(_date[0]).toFormat('yyyy-MM-dd')}&to=${DateTime.fromJSDate(_date[1]).toFormat('yyyy-MM-dd')}`

        api.get('stats/conversions/' + id + query).then((res) => {
            setConversions(res.data || [])
            setCurrentUser(res.user || {})
            setLoad(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSelectDate = (dates) => {
        setDate(dates)
        getData(dates)
    }


    function groupBy(key) {
        return function group(array) {
            return array.reduce((acc, obj) => {
                const property = obj[key];
                acc[property] = acc[property] || [];
                acc[property].push(obj);
                return acc;
            }, {});
        };
    }


    return (
        <>
            <Helmet>
                <title> Статистика | Robin Leads </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h4" gutterBottom>
                            Статистика по
                            конверсии {currentUser?.username ? 'пользователя - ' + currentUser?.username : undefined}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} textAlign={'right'}>
                        <DateRangePicker onChange={handleSelectDate} value={date} format="dd.MM.y" locale="RU-ru"
                                         maxDate={DateTime.now().toJSDate()} clearIcon={null}
                                         calendarIcon={<Iconify icon={'ph:calendar-duotone'}/>} disabled={isLoad}/>
                    </Grid>
                </Grid>


                <Grid mb={3}>
                    {isLoad ? <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                        <CircularProgress/>
                    </Stack> : <>
                        <Grid container mb={3} spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <AppCurrentVisits
                                    chartData={[
                                        {
                                            label: 'На проверке',
                                            value: conversions.filter((item) => (item.status === 'pending')).length
                                        },
                                        {
                                            label: 'Отклонено',
                                            value: conversions.filter((item) => (item.status === 'rejected')).length
                                        },
                                        {
                                            label: 'Одобрено',
                                            value: conversions.filter((item) => (item.status === 'approved')).length
                                        },
                                    ]}
                                    chartColors={[
                                        theme.palette.warning.main,
                                        theme.palette.error.main,
                                        theme.palette.success.main,
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <AppConversionRates
                                    title="Заявок"
                                    chartData={[
                                        ...Object.entries(groupByOfferId(conversions)).map(item => {
                                            return {
                                                label: item[1][0].offer_name,
                                                value: item[1].length
                                            }
                                        })
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Название</TableCell>
                                        <TableCell>Цель</TableCell>
                                        <TableCell align="right">Валюта</TableCell>
                                        <TableCell align="right">Дата</TableCell>
                                        <TableCell align="right">Статус</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {conversions.map((conversion) => (
                                        <TableRow hover key={conversion.conversion_id}
                                                  sx={{'&:last-child td, &:last-child th': {border: 0}}} style={{cursor: 'pointer'}} onClick={() => navigate(`/dashboard/reports/history/${id}/${conversion.client_id}`)}>
                                            <TableCell component="th" scope="row">{conversion.conversion_id}</TableCell>
                                            <TableCell>{conversion.offer_name}</TableCell>
                                            <TableCell>{conversion.goal_name}</TableCell>
                                            <TableCell align="right">{conversion.currency}</TableCell>
                                            <TableCell align="right">{conversion.conversion_date}</TableCell>
                                            <TableCell align="right">{conversion.status === 'rejected' ? <span
                                                style={{color: "#E23039"}}>Отклонено</span> : conversion.status === 'pending' ?
                                                <span
                                                    style={{color: "#F1A105"}}>На проверке</span> : conversion.status === 'approved' ?
                                                    <span style={{color: "#488C26"}}>Одобрено</span> :
                                                    <span>Неизвестно</span>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                </Grid>
            </Container>
        </>
    );
}
