import {Helmet} from 'react-helmet-async';
import {useEffect, useState} from 'react';
import {
    Stack,
    Button,
    Container,
    Typography,
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import Api from "../Api";
import Iconify from "../components/iconify";



export default function ProductPage() {
    const {access_token, user} = useAuth();
    const {id} = useParams();

    const [data, setData] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        const api = new Api(access_token)
        api.get(`products/${id}`).then((res) => {
            setData(res)
        })
    }, [id])


    return (
        <>
            <Helmet>
                <title>{data?.name || "Продукт"} | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data?.name}
                    </Typography>

                    {user.role === 'super_admin' ? <Button onClick={() => {
                        navigate("/dashboard/products/update/" + id)
                    }} variant="contained" startIcon={<Iconify icon="mingcute:edit-line"/>}>
                        Редактировать
                    </Button> : undefined}
                </Stack>

                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={5} gap={3}>
                    {data?.image ? <img src={data?.image} width={300} alt={data?.name}></img> : undefined }
                    {data?.description ? <p>{data?.description}</p> : undefined }
                </Stack>

            </Container>
        </>
    );
}
