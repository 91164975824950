import {Helmet} from 'react-helmet-async';
import {filter} from 'lodash';
import {useEffect, useState} from 'react';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    DialogTitle,
    DialogActions,
    Dialog,
    DialogContentText,
    DialogContent,
} from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import {UserListHead, UserListToolbar} from '../sections/@dashboard/user';
import Api from "../Api";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import ProductLinkModal from "../components/product-link-modal";
import ProductAnInvoiceUser from "../components/product-an-invoice-user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'index', label: '№', alignRight: false},
    {id: 'name', label: 'Название', alignRight: false},
    {id: 'sum', label: 'Цена', alignRight: false},
    {
        id: 'type', label: 'Категория', alignRight: true
    },
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, type) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    if (query) {
        return filter(array, (_product) => _product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    const res = stabilizedThis.map((el) => el[0])

    if (type) {
        return filter(res, (_product) => _product.type.toLowerCase().indexOf(type.toLowerCase()) !== -1);
    }

    return res;
}

export default function ProductsPage() {
    const {access_token, user} = useAuth();

    const navigate = useNavigate();

    const [open, setOpen] = useState(null);

    const [openModal, setOpenModal] = useState(null);
    const [OpenLinkModal, setOpenLinkModal] = useState(false);
    const [openAnInvoiceUserModal, setOpenAnInvoiceUserModal] = useState(false);
    const [openAnInvoiceUserModalAdmin, setOpenAnInvoiceUserModalAdmin] = useState(false);

    const [productsList, setProductsList] = useState([]);
    const [currentPorudct, setCurrentPorudct] = useState({});

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [typeFilter, setTypeFilter] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [bannerIndex, setBannerIndex] = useState(getRandomNumber(1, 4));

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min)
    }

    useEffect(() => {
        const api = new Api(access_token)

        api.get('products').then((products) => {
            setProductsList(products.map((p, index) => {
                return {...p, index: index + 1}
            }))
        })
    }, [])

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = productsList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleModalClose = (event) => {
        setOpenModal(false)
    }

    const handleDeleteUser = (event) => {
        setProductsList(productsList.map((product) => {
            return {
                ...product,
                is_delete: product.id === openModal.id || product.is_delete
            }
        }))

        const api = new Api(access_token)
        api.delete(`products/${openModal.id}`)

        setOpenModal(null)
        setOpen(null)
    }

    function handleLinkProduct(event) {
        const id = parseInt(open.value)
        const find = productsList.find((product) => {
            if (product.id === id) {
                return product
            }
        })

        if (find) {
            setCurrentPorudct(find)
            setOpenLinkModal(true)
        }
    }

    function handleEditProduct(event) {
        const id = parseInt(open.value)

        if (id) {
            navigate("/dashboard/products/update/" + id)
        }
    }

    function handleProductClick(id) {
        if (id) {
            navigate("/dashboard/products/" + id)
        }
    }

    const handleOpenModalDeleteUser = (event) => {
        const id = parseInt(open.value)

        if (id) {
            const item = productsList.find((item) => item.id === id)
            setOpenModal(item)
        }
    }

    const handleAnInvoice = (event) => {
        const id = parseInt(open.value)
        const find = productsList.find((product) => {
            if (product.id === id) {
                return product
            }
        })

        if (find) {
            setCurrentPorudct(find)
            setOpenAnInvoiceUserModal(true)
        }
    }

    const handleAnInvoiceAdmin = (event) => {
        const id = parseInt(open.value)
        const find = productsList.find((product) => {
            if (product.id === id) {
                return product
            }
        })

        if (find) {
            setCurrentPorudct(find)
            setOpenAnInvoiceUserModalAdmin(true)
        }
    }

    const handleTypeSelect = (e, node) => {
        setTypeFilter(node.props.value)
    }

    const handleCopy = (text) => {
        if (window.clipboardData && window.clipboardData.setData) {
            return window.clipboardData.setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", text);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productsList.length) : 0;

    const filteredProucts = applySortFilter(productsList, getComparator(order, orderBy), filterName, typeFilter);

    const isNotFound = !filteredProucts.length && !!filterName;

    function getPrice(product) {
        let price = product.sum;

        if (product.links && product.links[0]) {
            const link = product.links[0]
            price = link?.price || 0

            if (link?.sum) {
                price -= link?.sum || 0
            }
        }

        return price
    }

    return (
        <>
            <Helmet>
                <title>Продукты | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Продукты
                    </Typography>

                    {user.role === 'super_admin' ? <Button onClick={() => {
                        navigate("/dashboard/products/create")
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
                        Создать
                    </Button> : undefined}
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <a href="https://t.me/+QuIquxsjZCcxZTRi" target="_blank"><img
                        src={"/assets/images/banner-" + bannerIndex + ".jpg"} alt="" style={{borderRadius: 10}}/></a>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName}
                                     onFilterName={handleFilterByName}/>

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={productsList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                    onChangeSelect={handleTypeSelect}
                                />
                                <TableBody>
                                    {filteredProucts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const {id, name, image, sum, type, is_delete, links, index} = row;
                                        const selectedUser = selected.indexOf(name) !== -1;

                                        return (
                                            <TableRow hover key={index} tabIndex={-1} selected={selectedUser} style={{
                                                display: is_delete ? "none" : "table-row"
                                            }}>
                                                <TableCell scope="row" align="left" padding="normal">{index}</TableCell>

                                                <TableCell onClick={() => handleProductClick(id)}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {image ? <img src={image} alt="photoURL" width={100} height={58}
                                                                      style={{
                                                                          objectFit: "cover",
                                                                          borderRadius: 6
                                                                      }}/> : undefined}
                                                        <Typography variant="subtitle2" noWrap>
                                                            {name}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left"
                                                           padding="normal">{getPrice(row)}р.</TableCell>

                                                <TableCell align="right"
                                                           padding="normal">{type === 'credit' ? "Кредитная" : type === 'debit' ? "Дебетовая" : type === 'mfo' ? "МФО" : "Не назначено"}</TableCell>

                                                <TableCell align="right">
                                                    {/*https://robin-leads.ru/redirect/${links[0]?.custom_link}*/}
                                                    {
                                                        links[0] ? (links[0]?.is_custom_link && links[0]?.custom_link) ?
                                                                <Button
                                                                    title={`https://robin-leads.ru/redirect/${links[0]?.custom_link}`}
                                                                    onClick={() => handleCopy(`https://robin-leads.ru/redirect/${links[0]?.custom_link}`)}><span
                                                                    style={{margin: "0 0.5em 0 0"}}>Copy</span> <Iconify
                                                                    icon="eva:copy-fill"/></Button> :
                                                                (links[0]?.link) ?
                                                                    <Button
                                                                        title={links[0]?.link}
                                                                        onClick={() => handleCopy(links[0]?.link)}><span
                                                                        style={{margin: "0 0.5em 0 0"}}>Copy</span> <Iconify
                                                                        icon="eva:copy-fill"/></Button>
                                                                    : undefined
                                                            : undefined
                                                    }
                                                    {user.role !== 'user' ?
                                                        <IconButton size="large" color="inherit"
                                                                    onClick={handleOpenMenu}
                                                                    value={id}>
                                                            <Iconify icon={'eva:more-vertical-fill'}/>
                                                        </IconButton>
                                                        : undefined}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Ничего нет
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        Ничего не найдено по запросу &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br/> Попробуйте проверить на опечатки или использовать полные
                                                        слова.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Показывать по:"
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={productsList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 190,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >

                {user.role === 'super_admin' ?
                    <><MenuItem onClick={handleEditProduct}>
                        <Iconify icon={'eva:edit-fill'} sx={{mr: 2}}/>
                        Редактировать
                    </MenuItem>
                        <hr/>
                    </> : undefined}

                <MenuItem onClick={handleLinkProduct}>
                    <Iconify icon={'ph:link-bold'} sx={{mr: 2}}/>
                    Связать
                </MenuItem>

                {user.role !== 'user' ? <MenuItem onClick={handleAnInvoice}>
                    <Iconify icon={'ph:link-bold'} sx={{mr: 2}}/>
                    Выставить польз.
                </MenuItem> : undefined}

                {user.role === 'super_admin' ? <MenuItem onClick={handleAnInvoiceAdmin}>
                    <Iconify icon={'ph:link-bold'} sx={{mr: 2}}/>
                    Выставить админ.
                </MenuItem> : undefined}

                {user.role === 'super_admin' ?
                    <>
                        <hr/>
                        <MenuItem sx={{color: 'error.main'}} onClick={handleOpenModalDeleteUser}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{mr: 2}}/>
                            Удалить
                        </MenuItem>
                    </> : undefined}
            </Popover>

            {user.role !== 'user' ? <ProductLinkModal onClose={() => setOpenLinkModal(false)} open={OpenLinkModal}
                                                      product={currentPorudct}/> : undefined}

            {user.role !== 'user' ?
                <ProductAnInvoiceUser onClose={() => setOpenAnInvoiceUserModal(false)} open={openAnInvoiceUserModal}
                                      product={currentPorudct}/> : undefined}

            {user.role === 'super_admin' ? <ProductAnInvoiceUser onClose={() => setOpenAnInvoiceUserModalAdmin(false)}
                                                                 open={openAnInvoiceUserModalAdmin} type="admin"
                                                                 product={currentPorudct}/> : undefined}

            <Dialog
                open={!!openModal}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены что хотите удалить этот продукт?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Восстановить продукт после удаления будет невозможно.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteUser}>Удалить</Button>
                    <Button onClick={handleModalClose} autoFocus variant="outlined" color="error">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
