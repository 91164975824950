import {Helmet} from 'react-helmet-async';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid, CircularProgress, TableHead
} from '@mui/material';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from 'react';
import Api from "../Api";
import {useParams} from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {DateTime} from "luxon";
import Iconify from "../components/iconify";
import {AppConversionRates} from "../sections/@dashboard/app";

export default function FinancePage() {
    const {access_token, user} = useAuth();
    const [stats, setStats] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const {id} = useParams();
    const [isLoad, setLoad] = useState(true);
    const [currentItem, setCurrentItem] = useState({});

    const [openModal, setOpenModal] = useState(null);

    const handleModalClose = (event) => {
        setOpenModal(false)
    }

    const handleModalOpen = (id) => {
        const find = stats.find((_s) => (_s.id === id))

        if(find) {
            setCurrentItem(find)
            setOpenModal(true)
        }
    }

    const getData = (limit: number = 100, offset: number = 0) => {
        setLoad(true)

        const api = new Api(access_token)
        const query = `?limit=${limit}&offset=${offset}`

        api.get('stats/finance/' + id + query).then((res) => {
            setStats(res.data || [])
            setCurrentUser(res.user || {})
            setLoad(false)
        })
    }

    useEffect(() => {
        getData(100, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title> Статистика | Robin Leads</title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h4" gutterBottom>
                            Счета {currentUser?.username ? 'пользователя - ' + currentUser?.username : undefined}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid mb={3}>
                    {isLoad ? <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                        <CircularProgress/>
                    </Stack> : <>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Лиды</TableCell>
                                        <TableCell>Офферы</TableCell>
                                        <TableCell>Валюта</TableCell>
                                        <TableCell>Статус</TableCell>
                                        <TableCell align={"right"}>Дата</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.map((stat, index) => (
                                        <TableRow hover key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}} onClick={() => handleModalOpen(stat.id)} style={{cursor: 'pointer'}}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{stat.leads_num}</TableCell>
                                            <TableCell>{stat.items_num}</TableCell>
                                            <TableCell>{stat.currency}</TableCell>
                                            <TableCell>{stat.status === 'paid'? <span style={{color: "green"}}>оплачен</span>: <span style={{color: "red"}}>не оплачен</span>}</TableCell>
                                            <TableCell
                                                align={"right"}>{stat.created_at}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                </Grid>

                <Dialog
                    open={!!openModal}
                    onClose={handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Счет от: ' + currentItem?.created_at}
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Название</TableCell>
                                        <TableCell>Лиды</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentItem.items ? currentItem.items.map((item, index) => (
                                        <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.leads_num}</TableCell>
                                        </TableRow>
                                    )) : undefined}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} autoFocus variant="outlined" color="error">
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}