import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import UsersPage from './pages/UsersPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import CreateProductPage from "./pages/CreateProductPage";
import CreateUserPage from "./pages/CreateUserPage";
import ConversionsPage from "./pages/ConversionsPage";
import StatisticsPage from "./pages/StatisticsPage";
import FinancePage from "./pages/FinancePage";
import OffersPage from "./pages/OffersPage";
import UpdateUserPage from "./pages/UpdateUserPage";
import UpdateProductPage from "./pages/UpdateProductPage";
import StorePage from "./pages/StorePage";
import CreateStorePage from "./pages/CreateStorePage";
import UpdateStorePage from "./pages/UpdateStorePage";
import ProductPage from "./pages/ProductPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import HistoryPage from "./pages/HistoryPage";
import RedirectPage from "./pages/RedirectPage";

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/products" />, index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        { path: 'reports/conversions/:id', element: <ConversionsPage /> },
        { path: 'reports/statistics/:id', element: <StatisticsPage /> },
        { path: 'reports/finance/:id', element: <FinancePage /> },
        { path: 'reports/offers/:id', element: <OffersPage /> },
        { path: 'reports/history/:id/:client_id', element: <HistoryPage /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'users/create', element: <CreateUserPage /> },
        { path: 'users/update/:id', element: <UpdateUserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'products/:id', element: <ProductPage /> },
        { path: 'products/create', element: <CreateProductPage /> },
        { path: 'products/update/:id', element: <UpdateProductPage /> },
        { path: 'store', element: <StorePage /> },
        { path: 'store/create', element: <CreateStorePage /> },
        { path: 'store/update/:id', element: <UpdateStorePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/redirect/:path',
      element: <RedirectPage />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
