import PropTypes from 'prop-types';
import {NavLink as RouterLink} from 'react-router-dom';
// @mui
import {Box, List, ListItemText} from '@mui/material';
//
import {StyledNavItem, StyledNavItemIcon} from './styles';
import {useAuth} from "../../hooks/useAuth";
import {useState} from "react";

// ----------------------------------------------------------------------

NavSection.propTypes = {
    data: PropTypes.array,
};

export default function NavSection({data = [], ...other}) {
    return (
        <Box {...other}>
            <List disablePadding sx={{p: 1}}>
                {data.map((item) => (
                    <NavItem key={item.title} item={item}/>
                ))}
            </List>
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({item}) {
    const {user} = useAuth();
    const {title, path, icon, info, is_admin, is_super_admin} = item;

    let isVisible = false;

    if (is_super_admin) {
        if (user.role === 'super_admin') isVisible = true
    }

    if (is_admin) {
        if (user.role === 'admin' || user.role === 'super_admin') isVisible = true
    }

    if (!is_super_admin && !is_admin) isVisible = true


    return <>
        {
            (!isVisible) ? <></> : <StyledNavItem
                component={RouterLink}
                to={path}
                sx={{
                    '&.active': {
                        color: 'text.primary',
                        bgcolor: 'action.selected',
                        fontWeight: 'fontWeightBold',
                    },
                }}
            >
                <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

                <ListItemText disableTypography primary={title}/>

                {info && info}
            </StyledNavItem>
        }
    </>;
}
