import {Helmet} from 'react-helmet-async';
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    FormControl, Select, DialogTitle, DialogActions, Dialog,
} from '@mui/material';
import Iconify from '../components/iconify';
import {useAuth} from "../hooks/useAuth";
import {useState, useRef} from "react";
import Api from "../Api";
import {useNavigate} from "react-router-dom";


export default function CreateProductPage() {
    const {access_token, user} = useAuth();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(null);

    const leadgidIdRef = useRef(null);
    const nameRef = useRef(null);
    const imageRef = useRef(null);
    const sumRef = useRef(null);
    const descriptionRef = useRef(null);
    const [category, setCategory] = useState('debit');

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    const handleCreateProduct = () => {
        const leadgidId = leadgidIdRef.current.value,
            name = nameRef.current.value,
            image = imageRef.current.value,
            sum = sumRef.current.value || 0,
            description = descriptionRef.current.value || 0

        const api = new Api(access_token)

        api.post('products', {
            name: name,
            sum: sum,
            image: image,
            description: description,
            leadgid_id: leadgidId,
            type: category
        }).then((response) => {
            setOpenModal(true)
        })
    }

    return (
        <>
            <Helmet>
                <title>Создать продукт | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Создать продукт
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="material-symbols:check"/>}
                            onClick={handleCreateProduct}>
                        Создать
                    </Button>
                </Stack>

                <Card>
                    <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>

                            <Select
                                value={category}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                            >
                                <MenuItem value="debit">Дебетная</MenuItem>
                                <MenuItem value="credit">Кредитная</MenuItem>
                                <MenuItem value="mfo">МФО</MenuItem>
                                <MenuItem value="investments">Инвестиции</MenuItem>
                                <MenuItem value="other">Другое</MenuItem>
                            </Select>

                            <hr/>
                            <TextField name="leadgid_id" label="Leadgid ID" variant="outlined" fullWidth required={true}
                                       inputRef={leadgidIdRef}/>

                            <TextField name="name" label="Название" required={true} variant="outlined" fullWidth
                                       inputRef={nameRef}/>

                            <TextField name="sum" label="Цена" required={true} variant="outlined" fullWidth
                                       inputRef={sumRef}/>

                            <TextField name="description" label="Описание" variant="outlined"
                                       fullWidth inputRef={descriptionRef}/>

                            <TextField name="image" label="Обложка" variant="outlined" fullWidth inputRef={imageRef}/>
                        </Stack>
                    </FormControl>
                </Card>

                <Dialog
                    open={!!openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        Продукт {nameRef?.current?.value} успешно создан.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenModal(false)
                        }} autoFocus variant="contained" color="error">
                            Закрыть
                        </Button>
                        <Button onClick={() => {
                            setOpenModal(false)
                            navigate("/dashboard/products")
                        }} autoFocus variant="contained" color="primary">
                            К продуктам
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}
