import {Helmet} from 'react-helmet-async';
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    FormControl, Select, Grid, InputAdornment, IconButton, DialogTitle, DialogActions, Dialog,
} from '@mui/material';
import Iconify from '../components/iconify';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../Api";

export default function UpdateUserPage(props) {
    const {access_token, user} = useAuth();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(null);
    const [usersAdmin, setUsersAdmin] = useState([])

    const usernameRef = useRef(null);
    const fullnameRef = useRef(null);
    const passwordRef = useRef(null);
    const tokenRef = useRef(null);
    const balanceRef = useRef(null);
    const holdBalanceRef = useRef(null);
    const bonusBalanceRef = useRef(null);
    const [role, setRole] = useState('user');
    const [createdUser, setCreatedUser] = useState("");

    useEffect(() => {
        const api = new Api(access_token)

        api.get(`users/${id}`).then((res) => {
            setData(res)

            if (res.role) {
                setRole(res.role)
            }

            if(res.created_user) {
                setCreatedUser(res.created_user.id)
            }
        })

        api.get('users?role=admin').then((response) => {
            setUsersAdmin(response)
        })
    }, [id])

    const [showPassword, setShowPassword] = useState(false);

    const handleCreatedUserSelect = (event) => {
        setCreatedUser(event.target.value)
    }

    const handleChange = (event) => {
        setRole(event.target.value);
    };

    const handleUpdateUser = () => {
        const username = usernameRef.current.value,
            password = passwordRef.current.value,
            fullname = fullnameRef.current.value,
            token = tokenRef.current.value,
            balance = balanceRef.current.value || 0,
            holdBalance = holdBalanceRef.current.value || 0,
            bonusBalance = bonusBalanceRef.current.value || 0,
            _createdUser = createdUser !== "" ? createdUser : undefined

        const api = new Api(access_token)

        api.put('users/' + id, {
            username: username,
            fullname: fullname,
            password: password,
            balance: balance,
            bonus_balance: bonusBalance,
            hold_balance: holdBalance,
            role: role,
            leadergid_token: token,
            created_user: _createdUser
        }).then((response) => {
            setOpenModal(true)
        })
    }

    return (
        <>
            <Helmet>
                <title>Обновить пользователя | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Обновить пользователя <b style={{color: "#2065d1"}}>{data?.username}</b>
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="dashicons:update"/>}
                            onClick={handleUpdateUser}>
                        Обновить
                    </Button>
                </Stack>

                <Card>
                    {data !== null ? <> <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>

                            <TextField name="token" label="API TOKEN" required={true} variant="outlined"
                                       defaultValue={data.leadergid_token} fullWidth inputRef={tokenRef}/>

                            <TextField name="fullname" label="Полное имя" variant="outlined" fullWidth
                                       defaultValue={data.fullname} inputRef={fullnameRef}/>

                            <TextField name="username" label="Логин" required={true} variant="outlined"
                                       defaultValue={data.username} fullWidth inputRef={usernameRef}/>

                            <TextField
                                name="password"
                                label="Пароль"
                                type={showPassword ? 'text' : 'password'}
                                required={true}
                                defaultValue={data.password}
                                inputRef={passwordRef}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {role !== "super_admin" ? <Select
                                value={role}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'Without label'}}
                            >
                                <MenuItem value="user">Пользователь</MenuItem>
                                <MenuItem value="admin">Продвинутый</MenuItem>
                            </Select> : undefined}

                            <TextField name="balance" label="Баланс" variant="outlined" fullWidth type="number"
                                       defaultValue={data.balance} inputRef={balanceRef}/>

                            <TextField name="hold_balance" label="Холд. баланс" variant="outlined"
                                       fullWidth type="number" defaultValue={data.hold_balance}
                                       inputRef={holdBalanceRef}/>

                            <TextField name="bonus_balance" label="Бонусы" variant="outlined"
                                       fullWidth type="number" defaultValue={data.bonus_balance}
                                       inputRef={bonusBalanceRef}/>

                            {/*<TextField name="created_user" label="Закрепить за" variant="outlined"*/}
                            {/*           fullWidth/>*/}
                        </Stack>
                    </FormControl>
                        <hr/>
                        <FormControl fullWidth>
                            <Stack spacing={3} sx={{padding: 2}}>
                                <Select
                                    displayEmpty
                                    id="created_user"
                                    value={createdUser}
                                    onChange={handleCreatedUserSelect}
                                >
                                    <MenuItem value="">
                                        <em>Закрепить за</em>
                                    </MenuItem>
                                    {usersAdmin.map(item => {
                                        return <MenuItem value={item.id} key={item.id}>{item.username}</MenuItem>
                                    })}
                                </Select>
                            </Stack>
                        </FormControl> </> : undefined}
                </Card>

                <Dialog
                    open={!!openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        Пользователь {usernameRef?.current?.value} успешно обновлен.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenModal(false)
                        }} autoFocus variant="contained" color="error">
                            Закрыть
                        </Button>
                        <Button onClick={() => {
                            setOpenModal(false)
                            navigate("/dashboard/users")
                        }} autoFocus variant="contained" color="primary">
                            К пользователям
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}
