import {Helmet} from 'react-helmet-async';
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    FormControl, Select, DialogTitle, DialogActions, Dialog,
} from '@mui/material';
import Iconify from '../components/iconify';
import {useAuth} from "../hooks/useAuth";
import {useState, useRef, useEffect} from "react";
import Api from "../Api";
import {useNavigate, useParams} from "react-router-dom";

export default function UpdateProductPage() {
    const {access_token, user} = useAuth();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(null);
    const {id} = useParams();
    const [data, setData] = useState(null)

    const leadgidIdRef = useRef(null);
    const nameRef = useRef(null);
    const imageRef = useRef(null);
    const sumRef = useRef(null);
    const descriptionRef = useRef(null);
    const [category, setCategory] = useState('debit');

    useEffect(() => {
        const api = new Api(access_token)

        api.get(`products/${id}`).then((res) => {
            setData(res)

            if (res.type) {
                setCategory(res.type)
            }
        })
    }, [id])

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    const handleUpdateProduct = () => {
        const leadgidId = leadgidIdRef.current.value,
            name = nameRef.current.value,
            image = imageRef.current.value,
            sum = sumRef.current.value || 0,
            description = descriptionRef.current.value || 0

        const api = new Api(access_token)

        api.put('products/' + id, {
            name: name,
            sum: sum,
            image: image,
            description: description,
            leadgid_id: leadgidId,
            type: category
        }).then((response) => {
            setOpenModal(true)
        })
    }

    return (
        <>
            <Helmet>
                <title>Обновить продукт | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Обновить продукт
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="dashicons:update"/>}
                            onClick={handleUpdateProduct}>
                        Обновить
                    </Button>
                </Stack>

                <Card>
                    {data ? <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>

                            <Select
                                value={category}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                            >
                                <MenuItem value="debit">Дебетная</MenuItem>
                                <MenuItem value="credit">Кредитная</MenuItem>
                                <MenuItem value="mfo">МФО</MenuItem>
                                <MenuItem value="investments">Инвестиции</MenuItem>
                                <MenuItem value="other">Другое</MenuItem>
                            </Select>

                            <hr/>
                            <TextField name="leadgid_id" label="Leadgid ID" variant="outlined" fullWidth required={true}
                                       inputRef={leadgidIdRef} defaultValue={data.leadgid_id}/>

                            <TextField name="name" label="Название" required={true} variant="outlined" fullWidth
                                       inputRef={nameRef} defaultValue={data.name}/>

                            <TextField name="sum" label="Цена" required={true} variant="outlined" fullWidth
                                       inputRef={sumRef} defaultValue={data.sum}/>

                            <TextField name="description" label="Описание" variant="outlined"
                                       fullWidth inputRef={descriptionRef} defaultValue={data.description}/>

                            <TextField name="image" label="Обложка" variant="outlined" fullWidth inputRef={imageRef} defaultValue={data.image}/>
                        </Stack>
                    </FormControl> : undefined }
                </Card>

                <Dialog
                    open={!!openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        Продукт {nameRef?.current?.value} успешно создан.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenModal(false)
                        }} autoFocus variant="contained" color="error">
                            Закрыть
                        </Button>
                        <Button onClick={() => {
                            setOpenModal(false)
                            navigate("/dashboard/products")
                        }} autoFocus variant="contained" color="primary">
                            К продуктам
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}
