import {Helmet} from 'react-helmet-async';
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    FormControl,
    Select,
    Grid,
    InputAdornment,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
} from '@mui/material';
import Iconify from '../components/iconify';
import {useAuth} from "../hooks/useAuth";
import {useEffect, useRef, useState} from "react";
import Api from "../Api";
import {useNavigate} from "react-router-dom";


export default function CreateUserPage() {
    const {access_token, user} = useAuth();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(null);
    const [usersAdmin, setUsersAdmin] = useState([])

    const [role, setRole] = useState('user');
    const [createdUser, setCreatedUser] = useState("");
    const usernameRef = useRef(null);
    const fullnameRef = useRef(null);
    const passwordRef = useRef(null);
    const tokenRef = useRef(null);
    const balanceRef = useRef(null);
    const holdBalanceRef = useRef(null);
    const bonusBalanceRef = useRef(null);

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const api = new Api(access_token)

        api.get('users?role=admin').then((response) => {
            setUsersAdmin(response)
        })
    }, [])

    const handleChange = (event) => {
        setRole(event.target.value);
    };

    const handleCreatedUserSelect = (event) => {
        setCreatedUser(event.target.value)
    }

    const handleCreateUser = () => {
        const username = usernameRef.current.value,
            fullname = fullnameRef.current.value,
            password = passwordRef.current.value,
            token = tokenRef.current.value,
            balance = balanceRef.current.value || 0,
            holdBalance = holdBalanceRef.current.value || 0,
            bonusBalance = bonusBalanceRef.current.value || 0,
            _createdUser = createdUser !== "" ? createdUser : undefined

        const api = new Api(access_token)

        api.post('users', {
            username: username,
            fullname: fullname,
            password: password,
            balance: balance,
            bonus_balance: bonusBalance,
            hold_balance: holdBalance,
            role: role,
            leadergid_token: token,
            created_user: _createdUser
        }).then((response) => {
            setOpenModal(true)
        })
    }

    return (
        <>
            <Helmet>
                <title>Создать пользователя | Robin Leads</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Создать пользователя
                    </Typography>
                    <Button onClick={handleCreateUser} variant="contained"
                            startIcon={<Iconify icon="material-symbols:check"/>}>
                        Создать
                    </Button>
                </Stack>

                <Card>
                    <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>

                            <TextField name="token" label="API TOKEN" required={true} variant="outlined" fullWidth
                                       inputRef={tokenRef}/>

                            <TextField name="fullname" label="Полное имя" variant="outlined" fullWidth
                                       inputRef={fullnameRef}/>

                            <TextField name="username" label="Логин" required={true} variant="outlined" fullWidth
                                       inputRef={usernameRef}/>

                            <TextField
                                inputRef={passwordRef}
                                name="password"
                                label="Пароль"
                                type={showPassword ? 'text' : 'password'}
                                required={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Select
                                value={role}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'Without label'}}
                            >
                                <MenuItem value="user">Пользователь</MenuItem>
                                <MenuItem value="admin">Продвинутый</MenuItem>
                            </Select>

                            <TextField name="balance" label="Баланс" variant="outlined" fullWidth type="number"
                                       inputRef={balanceRef}/>

                            <TextField name="hold_balance" label="Холд. баланс" variant="outlined"
                                       fullWidth type="number" inputRef={holdBalanceRef}/>

                            <TextField name="bonus_balance" label="Бонусы" variant="outlined"
                                       fullWidth type="number" inputRef={bonusBalanceRef}/>
                        </Stack>
                    </FormControl>
                    <hr/>
                    <FormControl fullWidth>
                        <Stack spacing={3} sx={{padding: 2}}>
                            <Select
                                displayEmpty
                                id="created_user"
                                value={createdUser}
                                onChange={handleCreatedUserSelect}
                            >
                                <MenuItem value="">
                                    <em>Закрепить за</em>
                                </MenuItem>
                                {usersAdmin.map(item => {
                                    return <MenuItem value={item.id} key={item.id}>{item.username}</MenuItem>
                                })}
                            </Select>
                        </Stack>
                    </FormControl>
                </Card>

                <Dialog
                    open={!!openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        Пользователь {usernameRef?.current?.value} успешно создан.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenModal(false)
                        }} autoFocus variant="contained" color="error">
                            Закрыть
                        </Button>
                        <Button onClick={() => {
                            setOpenModal(false)
                            navigate("/dashboard/users")
                        }} autoFocus variant="contained" color="primary">
                            К пользователям
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}
