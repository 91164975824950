import {createContext, useContext, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import Api from "../Api";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [access_token, setAccessToken] = useLocalStorage("access_token", null);
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    useEffect(() => {
        if(access_token) {
            const api = new Api(access_token);

            api.get('profile').then((profile) => {
                setUser(profile)
            })
        }
    }, [access_token])

    // call this function when you want to authenticate the user
    const login = async (data) => {
        const api = new Api(data);
        const profile = await api.get('profile')
        setAccessToken(data);
        setUser(profile)
        navigate("/dashboard/products");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setAccessToken(null);
        setUser(null);
        navigate("/login", {replace: true});
    };

    const value = useMemo(
        () => ({
            access_token,
            user,
            login,
            logout
        }),
        [user, access_token]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};