import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// @mui
import {styled, alpha} from '@mui/material/styles';
import {Box, Link, Button, Drawer, Typography, Avatar, Stack} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import navConfigReports from "./config-reports";
import {useAuth} from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({openNav, onCloseNav}) {
    const StyledAccount = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 2.5),
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
        backgroundColor: user.role === 'admin' ? "#e9e9ff" : user.role === 'super_admin' ? "#ffeb9e" : alpha(theme.palette.grey[500], 0.12),
    }));

    const {pathname} = useLocation();
    const {user} = useAuth();

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
                <Logo/>
            </Box>

            <Box sx={{mb: 2, mx: 2.5}}>
                <Link underline="none">
                    <StyledAccount>
                        <Avatar src={user.image || "/assets/images/avatars/avatar.png"} alt="photoURL"/>

                        <Box sx={{ml: 2}}>
                            <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                                {user.username}
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                {user.role === "super_admin" ? "Администратор" : user.role === "admin" ? "Продвинутый" : "Пользователь"}
                            </Typography>
                        </Box>
                    </StyledAccount>
                </Link>
            </Box>

            <Box sx={{mb: 5, mx: 2.5}}>
                <Link underline="none">
                    <StyledAccount>
                        <Box sx={{ml: 0}}>
                            <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                                Баланс: {user.balance || 0}р
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                Баланс в холде: {user.hold_balance || 0}р
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                Бонусы: {user.bonus_balance || 0}
                            </Typography>
                        </Box>
                    </StyledAccount>
                </Link>
            </Box>

            <NavSection data={navConfig}/>

            <Box sx={{mb: 0, mt: 3, mx: 2.5}}>
                <Typography variant="body1" sx={{color: 'text.secondary'}}>Отчеты</Typography>
            </Box>
            <NavSection data={navConfigReports(user.id)}/>

            <Box sx={{flexGrow: 1}}/>
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: {lg: 0},
                width: {lg: NAV_WIDTH},
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {width: NAV_WIDTH},
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
