import PropTypes from 'prop-types';
// @mui
import {Box, TableRow, TableCell, TableHead, TableSortLabel, Select, MenuItem} from '@mui/material';
import {useAuth} from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
};

export default function UserListHead({
                                         order,
                                         orderBy,
                                         headLabel,
                                         onRequestSort,
                                         onChangeSelect
                                     }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const {access_token, user} = useAuth();

    return (
        <TableHead>
            <TableRow>
                {headLabel.map((headCell) => (
                    !headCell.only_admin || (headCell.only_admin && user.role === 'super_admin') ?
                        <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? 'right' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell?.id !== 'type' ? <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box
                                        sx={{...visuallyHidden}}>{order === 'desc' ? 'отсортировано по убыванию' : 'отсортировано по возрастанию'}</Box>
                                ) : null}
                            </TableSortLabel> : <Select
                                displayEmpty={true}
                                defaultValue=""
                                variant="standard"
                                value={headCell.value}
                                label={headCell.label}
                                onChange={onChangeSelect}
                            >
                                <MenuItem value="">Все</MenuItem>
                                <MenuItem value="debit">Дебетовая</MenuItem>
                                <MenuItem value="credit">Кредитная</MenuItem>
                                <MenuItem value="mfo">МФО</MenuItem>
                                <MenuItem value="investments">Инвестиции</MenuItem>
                                <MenuItem value="other">Другое</MenuItem>
                            </Select>}
                        </TableCell> : undefined
                ))}
            </TableRow>
        </TableHead>
    );
}
